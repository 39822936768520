export const initialNotes = [
  {
    id: 1321,
    title: "Купить продукты",
    content: "Молоко, хлеб, яйца, сыр",
    creationDate: "2024-02-01",
    priority: "Высокий",
    reminderDate: "2024-03-20",
    completionStatus: "Не выполнено",
  },
  {
    id: 1232,
    title: "Занятие по йоге",
    content: "Не забыть взять коврик и бутылку воды",
    creationDate: "2024-03-07",
    priority: "Средний",
    reminderDate: "2024-03-19",
    completionStatus: "Не выполнено",
  },
  {
    id: 1254,
    title: "Встреча с друзьями",
    content: "Позвонить и договориться о месте",
    creationDate: "2024-02-07",
    priority: "Низкий",
    reminderDate: "2024-03-21",
    completionStatus: "Выполнено",
  },
  {
    id: 1265,
    title: "Сдать отчетность",
    content: "Закончить квартальный отчет и отправить начальнику",
    creationDate: "2024-02-15",
    priority: "Высокий",
    reminderDate: "2024-03-22",
    completionStatus: "Не выполнено",
  },
  {
    id: 1245,
    title: "Поход к врачу",
    content: "Ежегодный осмотр у терапевта",
    creationDate: "2024-03-06",
    priority: "Средний",
    reminderDate: "2024-03-23",
    completionStatus: "Не выполнено",
  },
];
