<template>
  <div v-if="isMobile" id="app">
    <router-view />
  </div>
  <div v-else>
    <!-- Сообщение для пользователей не на мобильных устройствах -->
    Извините, этот сайт доступен только с мобильных устройств.
  </div>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    isMobile: false,
  }),
  created() {
    this.checkDeviceType();
  },
  methods: {
    checkDeviceType() {
      const userAgent = navigator.userAgent.toLowerCase();
      this.isMobile = /iphone|ipod|android|ie|blackberry|fennec/.test(
        userAgent
      );
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
